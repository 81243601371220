<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione Agenzie Partner</span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Elenco Agenzie Partner</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" @click="$refs.partnerAddModal.open()">
            Aggiungi Agenzia
          </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="partnersProvider"
        :busy="loading"
        :filter="filter"
        :current-page="currentPage"
        striped
        show-empty
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle agenzie partner...</strong>
          </div>
        </template>

        <template #empty>
            <h5 class="text-primary-light text-center my-2">Non ci sono agenzie partner</h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">Non sono state trovate agenzie partner</h5>
        </template>

        <template #cell(nome_agenzia)="data">
          {{ data.item.agency_name}}
        </template>

        <template #cell(opzioni)="data">
          <feather-icon class="cursor-pointer" icon="EditIcon" size="20" @click="$refs.partnerEditModal.open(data.item)" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
        </template>

      </b-table>

      <b-pagination
        v-if="partners.count > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="partners.count"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <!-- Modali -->

    <partner-add-modal ref="partnerAddModal" @hide="getPartners"></partner-add-modal>
    <partner-edit-modal ref="partnerEditModal"></partner-edit-modal>

    


  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  VBTooltip,
  BPagination,
} from "bootstrap-vue";

import { PartnerAddModal, PartnerEditModal } from "@/components/modals";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
    BPagination,
    PartnerAddModal,
    PartnerEditModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "nome_agenzia",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    sortBy: "agency_name",
    sortDesc: false,
    partners: [],

    //Helpers

    perPage: 10,
    currentPage: 1,
    loading: false,
  }),

  methods: {
    async getPartners(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getPartners(request_data);
        this.partners = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

      async partnersProvider(ctx) {
      await this.getPartners();
      return this.partners.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
